<div class="flex align-items-center gap-2 text-xs">
  @if (showIcon()) {
    <span class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
      [ngClass]="{
        'bg-red-500': attempt().state === 'FAILED',
        'bg-orange-500': attempt().state === 'PENDING' || attempt().state === 'DELIVERY_FAILED',
        'bg-blue-500': attempt().state === 'SENT',
        'bg-green-500': attempt().state === 'DELIVERY_CONFIRMED',
      }"
    >
      @switch (attempt().state) {
        @case ('FAILED') {
          <span class="pi pi-times text-xs"></span>
        }

        @case ('INITIAL') {
          <span class="pi pi-clock text-xs text-gray-600"></span>
        }

        @case ('PENDING') {
          <span class="pi pi-clock text-xs"></span>
        }

        @case ('SENT') {
          <span class="pi pi-check text-xs"></span>
        }

        @case ('DELIVERY_CONFIRMED') {
          <span class="pi pi-check text-xs"></span>
        }

        @case ('DELIVERY_FAILED') {
          <span class="pi pi-exclamation-circle"></span>
        }
      }
    </span>
  }
  <div class="flex flex-column">
    @switch (attempt().state) {
      @case ('FAILED') {
        @if (showTitle()) {
          <p class="m-0"><b>Eroare la trimitere</b></p>
        }
        @if (showText()) {
          @if (attempt().createdAt) {
            <p class="text-gray-400 m-0">Creat la {{ attempt().createdAt | date: "dd MMMM HH:mm" }}</p>
          }
        }
      }

      @case ('INITIAL') {
        @if (showTitle()) {
        <p class="m-0"><b>În așteptare</b></p>
        }
        @if (showText()) {
          @if (attempt().createdAt) {
            <p class="text-gray-400 m-0">Creat la {{ attempt().createdAt | date: "dd MMMM HH:mm" }}</p>
          }
        }
      }

      @case ('PENDING') {
        @if (showTitle()) {
          <p class="m-0"><b>În așteptare trimitere</b></p>
        }
        @if (showText()) {
          @if (attempt().createdAt) {
            <p class="text-gray-400 m-0">Creat la {{ attempt().createdAt | date: "dd MMMM HH:mm" }}</p>
          }
        }
      }

      @case ('SENT') {
        @if (showTitle()) {
          <p class="m-0"><b>Trimis</b></p>
        }
        @if (showText()) {
          @if (attempt().sentAt) {
            <p class="text-gray-400 m-0">Trimis la {{ attempt().sentAt | date: "dd MMMM HH:mm" }}</p>
          }
        }
      }

      @case ('DELIVERY_CONFIRMED') {
        @if (showTitle()) {
          <p class="m-0"><b>Trimis și livrat</b></p>
        }
        @if (showText()) {
          @if (attempt().sentAt) {
            <p class="text-gray-400 m-0">
              Trimis la
              {{ attempt().sentAt | date: "dd MMMM HH:mm" }}
              si livrat la
              {{ attempt().deliveredAt | date: "dd MMMM HH:mm" }}
            </p>
          }
        }
      }

      @case ('DELIVERY_FAILED') {
        @if (showTitle()) {
          <p class="m-0"><b>Trimis și nelivrat</b></p>
        }
        @if (showText()) {
          @if (attempt().sentAt) {
            <p class="text-gray-400 m-0">Trimis la {{ attempt().sentAt | date: "dd MMMM HH:mm" }}</p>
          }
        }
      }
    }
  </div>
</div>
import { CBox_LockerPickUpRequestData } from "../locker/types";

export type CBox_ActionCreatePickUpData = {
  awb: string;
  uniqueReference?: string;
  locker: { id: string; } | { name: string; }
  contactInfo?: {
    sender?: {
      name?: string;
      county?: string;
      address?: string;
      postalCode?: string;
      phone?: string;
      email?: string;
    },
    recipient?: {
      name?: string;
      county?: string;
      address?: string;
      postalCode?: string;
      phone?: string;
      email?: string;
    }
  }
  parcel: {
    weight?: number;
    length: number;
    width: number;
    height: number;
  } | {
    weight?: number;
    predefinedBoxIdentifier: string;
  };
  reservation?: {
    enabled: false;
  } | {
    enabled: true;
    optional?: boolean;
    offset?: number;
    until?: string;
  }
  retention?: {
    offset: number;
  }
  notify?: {
    sms?: {
      enabled: boolean;
      phone: string;
    }
    email?: {
      enabled: boolean;
      email: string;
    }
  }
};

export type CBox_ActionCreatePickUpResponse = {
  responseId: string;
  reservedUntil: string | null;
};

export type CBox_ActionDeletePickUpParams = {
  awb?: string;
  responseId?: string;
  uniqueReference?: string;
};

export type CBox_ActionDeletePickUpResponse = undefined;

export type CBox_ActionUpdatePickUpParams = {
  query: {
    awb?: string;
    responseId?: string;
    uniqueReference?: string;
  },
  body: {
    retention?: {
      reenable: boolean;
      extend: {
        offsetStartPoint: "now" | "original";
        offset: number;
      } | { until: string; }
    }
    notify?: {
      sms?: {
        change?: {
          phone: string;
        },
        resend: boolean;
      }
      email?: {
        change?: {
          email: string;
        }
        resend: boolean;
      }
    },
    markAsDelivered?: boolean;
  }
};

export type CBox_ActionUpdatePickUpResponse = undefined;

export type CBox_GetPickUpRequestParams = (
  {
      awb: string;
    }
  | {
      responseId: string;
    }
  | {
      uniqueReference: string;
    }
) & {
  includeCode?: boolean;
  includeActions?: boolean;
  includeExternalStatusSyncs?: boolean;
  beforeDate?: string;
};

export type CBox_GetPickUpDataFlags = {
  isInLocker: boolean;
  isFinished: boolean;
  isCanceled: boolean;
  isExpired: boolean;
  isPickedUpByUser: boolean;
  isPickedBackByCourier: boolean;
  isIndeterminate: boolean;
};

export enum CBox_RequestActionEnum {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  UPDATE_PHONE = "UPDATE_PHONE",
  UPDATE_EMAIL = "UPDATE_EMAIL",
  RESEND_SMS = "RESEND_SMS",
  RESEND_EMAIL = "RESEND_EMAIL",
  CODE_ACCESS = "CODE_ACCESS",
  EXTEND = "EXTEND",
  CANCEL = "CANCEL"
}

type CBox_RequestCreateActionLogData = {
  data: CBox_ActionCreatePickUpData;
};
type CBox_RequestUpdateActionLogData = null;
type CBox_RequestUpdatePhoneActionLogData = {
  phone: {old: string, new: string}
};
type CBox_RequestUpdateEmailActionLogData = {
  email: {old: string, new: string}
};
type CBox_RequestResendSMSActionLogData = { phone: string };
type CBox_RequestResendEmailActionLogData = { email: string };
type CBox_RequestCodeAccessActionLogData = null;
type CBox_RequestExtendActionLogData = {
  until: {old: string, new: string},
  data: CBox_ActionUpdatePickUpParams["body"]["retention"]
};
type CBox_RequestCancelActionLogData = null;

export type CBox_RequestActionLogData = {
  [CBox_RequestActionEnum.CREATE]: CBox_RequestCreateActionLogData;
  [CBox_RequestActionEnum.UPDATE]: CBox_RequestUpdateActionLogData;
  [CBox_RequestActionEnum.UPDATE_PHONE]: CBox_RequestUpdatePhoneActionLogData;
  [CBox_RequestActionEnum.UPDATE_EMAIL]: CBox_RequestUpdateEmailActionLogData;
  [CBox_RequestActionEnum.RESEND_SMS]: CBox_RequestResendSMSActionLogData;
  [CBox_RequestActionEnum.RESEND_EMAIL]: CBox_RequestResendEmailActionLogData;
  [CBox_RequestActionEnum.CODE_ACCESS]: CBox_RequestCodeAccessActionLogData;
  [CBox_RequestActionEnum.EXTEND]: CBox_RequestExtendActionLogData;
  [CBox_RequestActionEnum.CANCEL]: CBox_RequestCancelActionLogData;
};

type GenericAction<T extends CBox_RequestActionEnum> = {
  type: T;
  performedBy: {
    identifier: string;
    name: string;
  };
  performedAt: string;
  data: CBox_RequestActionLogData[T];
};

export type CBox_RequestActionLog = {
  [T in CBox_RequestActionEnum]: GenericAction<T>
}[CBox_RequestActionEnum];

export type CBox_GetPickUpDataResponse = CBox_LockerPickUpRequestData & {
  publicReference?: string;
  openCode?: {
    active: boolean;
    code: string;
  }
  locker: {
    id: string;
    name: string;
  }
  contactInfo?: {
    sender?: {
      name?: string;
      county?: string;
      address?: string;
      postalCode?: string;
      phone?: string;
      email?: string;
    },
    recipient?: {
      name?: string;
      county?: string;
      address?: string;
      postalCode?: string;
      phone?: string;
      email?: string;
    }
  };
  flags: CBox_GetPickUpDataFlags;
  history: {
    code: string;
    description: string;
    triggeredByName: string;
    occurredAt: string;
    externalSync?: {
      status: string;
      executedAt: string;
    }[];
  }[];
  actionLog?: CBox_RequestActionLog[];
};

export type CBox_GetPickUpListRequestParams = {
  lockerId?: string;
  lockerName?: string;
  page?: number;
  pageSize?: number;
  includeNew?: boolean;
  includeInLocker?: boolean;
  includeFinalized?: boolean;
  includeExpired?: boolean;
  includeCanceled?: boolean;
  afterDate?: string;
  beforeDate?: string;
  awb?: string;
  responseId?: string;
  uniqueReference?: string;
};

export type CBox_GetPickUpListItemDataResponse = CBox_LockerPickUpRequestData &
{
  locker: {
    id: string;
    name: string;
  }
  flags: CBox_GetPickUpDataFlags;
};
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import { NotificationType, NotifySendState } from '../../cbox-profile-pickup-request-info.component';

@Component({
  selector: 'app-cbox-profile-request-pickup-info-message-status-display',
  templateUrl: './cbox-profile-request-pickup-info-message-status-display.component.html',
  styleUrls: ['./cbox-profile-request-pickup-info-message-status-display.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
  ]
})
export class CboxProfileRequestPickupInfoMessageStatusDispalyComponent {

  showTitle = input<boolean>(true);
  showText = input<boolean>(true);
  showIcon = input<boolean>(true);
  attempt = input.required<NotificationType["templates"][0]['attempts'][0]>();

  constructor() {}

}
